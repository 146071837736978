import { forwardRef, memo, useCallback, useMemo, useRef } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type {
  CellEditingStartedEvent,
  EditableCallbackParams,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IsGroupOpenByDefaultParams,
  Module,
  NewValueParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

import { useWhatPropsChanged } from 'src/hooks/useWhatPropsChanged';
import {
  useAggregationFeature,
  useColumnGroupingFeature,
  useColumnOrderingFeature,
  useColumnPinningFeature,
  useColumnsFeature,
  useColumnVisibilityFeature,
  useColumnWidthsFeature,
  useEditingFeature,
  useFilteringFeature,
  useGridInitialisation,
  useRowGroupingFeature,
  useRowsFeature,
  useSortingFeature,
} from './hooks';
import { initialGroupOrderComparator } from './initialGroupOrderComparator';
import { DEFAULT_GRID_OPTIONS } from './options';
import type {
  AggregationFunction,
  AggregationModel,
  Column,
  ColumnGroupingModel,
  ColumnOrderModel,
  ColumnVisibilityModel,
  ColumnWidthModel,
  FilterModel,
  FormulaFunction,
  GridOptions,
  GroupingColumn,
  PinnedColumnsModel,
  Row,
  RowGroupingModel,
  SortModel,
} from './types';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';

export interface DataGridProps<R extends Row> {
  /** The aggregation model of the grid. */
  aggregation?: AggregationModel | undefined;

  /** Custom aggregation functions. */
  aggregationFunctions?: Record<string, AggregationFunction>;

  /** Should the ability to toggle column visibility be allowed from the column menu */
  canChangeColumnVisibility?: boolean | undefined;

  /** Should the user be able to change the order of columns by dragging them around */
  canChangeColumnOrder?: boolean | undefined;

  /** Should the grouping option be available from the general menu tab */
  canChangeRowGrouping?: boolean | undefined;

  /** The column grouping model of the grid. */
  columnGrouping?: ColumnGroupingModel | undefined;

  /** The column order model of the grid. */
  columnOrder?: ColumnOrderModel | undefined;

  /** An array of columns. */
  columns: Column[];

  /** The column visibility model of the grid. */
  columnVisibility?: ColumnVisibilityModel | undefined;

  /** The column width model of the grid. */
  columnWidths?: ColumnWidthModel | undefined;

  /** If true, additional logging will be output. */
  debug?: boolean;

  /** doesExternalFilterPass is called once for each row node in the grid. If you return false, the node will be excluded from the final set. */
  doesExternalFilterPass?: GridOptions<R>['doesExternalFilterPass'];

  /** Set to true to enable a handle inside the last cell that allows you to run operations on cells as you adjust the size of the range.  Use in conjunction with fillOperation and enableRangeSelection. Default: false */
  enableFillHandle?: GridOptions<R>['enableFillHandle'];

  /** Set to true to enable Range selection which allows Excel-like range selection of cells. Default: true */
  enableRangeSelection?: GridOptions<R>['enableRangeSelection'];

  /** Set to true along with enterNavigatesVerticallyAfterEdit to have Excel-style behaviour for the Enter key. i.e. pressing the Enter key will move down to the cell beneath. Default: false */
  enterNavigatesVertically?: GridOptions<R>['enterNavigatesVertically'];

  /** Set to true along with enterNavigatesVertically to have Excel-style behaviour for the 'Enter' key. i.e. pressing the Enter key will move down to the cell beneath. Default: false */
  enterNavigatesVerticallyAfterEdit?: GridOptions<R>['enterNavigatesVerticallyAfterEdit'];

  /** The filter model of the grid. */
  filter?: FilterModel | undefined;

  /** In conjunction with enableFillHandle and enableRangeSelection this callback is used to fill values instead of simply copying values or increasing number values using linear progression. */
  fillOperation?: GridOptions<R>['fillOperation'];

  /** Whether the filter model is defined by a View, and so should not be changed by the user. */
  filterIsViewDefined?: boolean;

  /** Formula functions available on the grid. */
  formulaFunctions?: Record<string, FormulaFunction>;

  /** Function for customising the context menu.  */
  getContextMenuItems?: GridOptions<R>['getContextMenuItems'];

  /** Callback version of property rowClass to set class(es) for each row individually. Function should return either a string (class name), array of strings (array of class names) or undefined for no class. */
  getRowClass?: GridOptions<R>['getRowClass'];

  /** Return the id of a given row. */
  getRowId?: GetRowIdFunc<R>;

  /** The grouping column. */
  groupingColumn?: GroupingColumn;

  /** Callback that should return whether a cell is editable. */
  isCellEditable?: (params: EditableCallbackParams) => boolean;

  /** Grid calls this method to know if an external filter is present. */
  isExternalFilterPresent?: GridOptions<R>['isExternalFilterPresent'];

  /**
   * Number of children to show when group is collapsed.
   */
  numbersOfGroupedColumnsToShow?: Record<string, number> | undefined;

  /** Determines if a group should be expanded after its creation. */
  isGroupExpandedByDefault?: (params: IsGroupOpenByDefaultParams) => boolean;

  /** Callback fired when the aggregation model changes. */
  onAggregationChange?: (model: AggregationModel) => void;

  /** Callback fired cell is double clicked. */
  onCellDoubleClicked?: GridOptions<R>['onCellDoubleClicked'];

  /** Callback fired when editing a cell has stopped. */
  onCellEditingStopped?: GridOptions<R>['onCellEditingStopped'];

  /** Callback fired when the value of a cell has changed. */
  onCellValueChange?: (event: NewValueParams) => void;

  /** Callback fired when the column order model changes. */
  onColumnOrderChange?: (model: ColumnOrderModel) => void;

  /** Callback fired when the column visibility model changes. */
  onColumnVisibilityChange?: (model: ColumnVisibilityModel) => void;

  /** Callback fired when the column width model changes. */
  onColumnWidthChange?: (model: ColumnWidthModel) => void;

  /** Callback fired when the filter model changes. */
  onFilterChange?: (model: FilterModel) => void;

  /** Callback fired when the grid has initialised and is ready for most api calls, but may not be fully rendered yet */
  onGridReady?: GridOptions<R>['onGridReady'];

  /** Callback fired when the pinned column model changes. */
  onPinnedColumnsChange?: (model: PinnedColumnsModel) => void;

  /** Callback fired when a row is clicked. */
  onRowClicked?: GridOptions<R>['onRowClicked'];

  /** Callback fired when a row is double clicked. */
  onRowDoubleClicked?: GridOptions<R>['onRowDoubleClicked'];

  /** Callback fired when the row grouping model changes. */
  onRowGroupingChange?: (model: RowGroupingModel) => void;

  /** Callback fired when the sort model changes. */
  onSortChange?: (model: SortModel) => void;

  /** The columns to display pinned to left or right. */
  pinnedColumns?: PinnedColumnsModel | undefined;

  /** Callback to perform additional sorting after the grid has sorted the rows. */
  postSortRows?: GridOptions<R>['postSortRows'];

  /* Allows complete control of the paste operation, including cancelling the operation (so nothing happens) or replacing the data with other data */
  processDataFromClipboard?: GridOptions<R>['processDataFromClipboard'];

  /** The row grouping model of the grid. */
  rowGrouping?: RowGroupingModel | undefined;

  /** The data to display as rows in the grid. */
  rows: R[];

  /**  Type of row selection, set to either 'single' or 'multiple' to enable selection. 'single' will use single row selection, such that when you select a row, any previously selected row gets unselected. 'multiple' allows multiple rows to be selected */
  rowSelection?: GridOptions<R>['rowSelection'];

  /** Allows you to get the data that would otherwise go to the clipboard. */
  sendToClipboard?: GridOptions<R>['sendToClipboard'];

  /** The sort model of the grid. */
  sort?: SortModel | undefined;

  /** Set to true to copy the cell range or focused cell to the clipboard and never the selected rows. Default: false */
  suppressCopyRowsToClipboard?: GridOptions<R>['suppressCopyRowsToClipboard'];

  /** Set to true to copy rows instead of ranges when a range with only a single cell is selected. Default: false */
  suppressCopySingleCellRanges?: GridOptions<R>['suppressCopySingleCellRanges'];

  /** Part of Range Selection; If true, only a single range can be selected. Default: false */
  suppressMultiRangeSelection?: GridOptions<R>['suppressMultiRangeSelection'];
}

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-064372}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{IWSR_Drinks_Market_Analysis_Limited}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_September_2025}____[v3]_[01]_MTc1ODMyMjgwMDAwMA==8f0b8b3b1cdd55d5e1b4d3cf168f1d7c'
);

const DataGridRaw = forwardRef(function DataGrid<R extends Row>(props: DataGridProps<R>) {
  const gridRef = useGridInitialisation<R>(
    {
      ...DEFAULT_GRID_OPTIONS,
      initialGroupOrderComparator,
    } as GridOptions<R>,
    props
  );

  const gridApiRef = useRef<GridApi<R>>();

  useWhatPropsChanged(props as unknown as Record<string, unknown>, Boolean(props.debug));

  useEditingFeature<R>(gridRef, gridApiRef, props);

  useColumnsFeature<R>(gridRef, gridApiRef, props);
  useColumnGroupingFeature<R>(gridRef, gridApiRef, props);

  useRowsFeature<R>(gridRef, gridApiRef, props);
  useRowGroupingFeature<R>(gridRef, gridApiRef, props);

  useColumnOrderingFeature<R>(gridRef, gridApiRef, props);
  useColumnPinningFeature<R>(gridRef, gridApiRef, props);
  useColumnVisibilityFeature<R>(gridRef, gridApiRef, props);
  useColumnWidthsFeature<R>(gridRef, gridApiRef, props);

  useAggregationFeature<R>(gridRef, gridApiRef, props);
  useFilteringFeature<R>(gridRef, gridApiRef, props);
  useSortingFeature<R>(gridRef, gridApiRef, props);

  const {
    aggregationFunctions,
    doesExternalFilterPass,
    enableFillHandle,
    enableRangeSelection,
    enterNavigatesVertically,
    enterNavigatesVerticallyAfterEdit,
    fillOperation,
    getContextMenuItems,
    getRowClass,
    isExternalFilterPresent,
    onCellDoubleClicked,
    onCellEditingStopped,
    onGridReady,
    onRowClicked,
    onRowDoubleClicked,
    postSortRows,
    processDataFromClipboard,
    rowSelection,
    sendToClipboard,
    suppressCopyRowsToClipboard,
    suppressCopySingleCellRanges,
    suppressMultiRangeSelection,
  } = props;

  const modules = useMemo<Module[]>(() => {
    return [
      ClientSideRowModelModule,
      MenuModule,
      RowGroupingModule,
      SetFilterModule,
      ClipboardModule,
      RangeSelectionModule,
      ExcelExportModule,
      ColumnsToolPanelModule,
    ];
  }, []);

  const options = useMemo(() => {
    return {
      aggFuncs: aggregationFunctions,
      doesExternalFilterPass,
      getContextMenuItems,
      getRowClass,
      isExternalFilterPresent,
      onCellDoubleClicked,
      onCellEditingStopped,
      onRowClicked,
      onRowDoubleClicked,
      postSortRows,
      sendToClipboard,
      processDataFromClipboard,
      rowSelection,
      suppressCopyRowsToClipboard,
      ...(suppressCopySingleCellRanges !== undefined ? { suppressCopySingleCellRanges } : {}),
      ...(enableRangeSelection !== undefined ? { enableRangeSelection } : {}),
      ...(suppressMultiRangeSelection !== undefined ? { suppressMultiRangeSelection } : {}),
      enterNavigatesVertically: !!enterNavigatesVertically,
      enterNavigatesVerticallyAfterEdit: !!enterNavigatesVerticallyAfterEdit,
      enableFillHandle,
      fillOperation,
    } as Partial<GridOptions<R>>;
  }, [
    aggregationFunctions,
    doesExternalFilterPass,
    enableFillHandle,
    enableRangeSelection,
    enterNavigatesVertically,
    enterNavigatesVerticallyAfterEdit,
    fillOperation,
    getContextMenuItems,
    getRowClass,
    isExternalFilterPresent,
    onCellDoubleClicked,
    onCellEditingStopped,
    onRowClicked,
    onRowDoubleClicked,
    postSortRows,
    processDataFromClipboard,
    rowSelection,
    sendToClipboard,
    suppressCopyRowsToClipboard,
    suppressCopySingleCellRanges,
    suppressMultiRangeSelection,
  ]);

  const handleGridReady = useCallback(
    (event: GridReadyEvent<R>) => {
      gridApiRef.current = event.api;
      onGridReady?.(event);
    },
    [gridApiRef, onGridReady]
  );

  const onCellEditingStarted = useCallback((event: CellEditingStartedEvent<R>) => {
    if (
      ((event.event as KeyboardEvent).code === 'Backspace' ||
        (event.event as KeyboardEvent).code === 'Delete') &&
      event.rowIndex !== null &&
      event.colDef.colId
    ) {
      event.api.stopEditing();
      event.api.setFocusedCell(event.rowIndex, event.colDef.colId);
    }
  }, []);

  return (
    <AgGridReact<R>
      {...gridRef.current}
      {...options}
      className="ag-theme-balham"
      modules={modules}
      onGridReady={handleGridReady}
      onCellEditingStarted={onCellEditingStarted}
      reactiveCustomComponents={false} // TODO: get rid of this and rewrite CustomSetFilter in reactive way https://www.ag-grid.com/react-data-grid/component-filter/
    />
  );
});

type DataGrid = <R extends Row>(props: DataGridProps<R>) => JSX.Element;

export const DataGrid = memo(DataGridRaw) as DataGrid;

import type {
  unsafe_ColDef,
  unsafe_RowNode,
  unsafe_SendToClipboardParams,
} from 'src/components/DataGrid';
import type { Entity } from '../../../types/Entity';
import { isRange } from './gridClipboardUtils';
import { copyRange } from './gridCopyRangeUtils';

const copyContent = async (colDef: unsafe_ColDef, data: Entity, cellValue: string) => {
  try {
    let valueToCopy = '';
    const fieldName: string = colDef.colId ?? '';
    const valueFieldName: string = fieldName.split('.')[0] ?? '';
    const valueFieldYear: string = fieldName.split('.')[1] ?? '';

    const yearValues = data[valueFieldName] ? (data[valueFieldName] as Entity) : undefined;
    const yearValue = yearValues ? (yearValues[valueFieldYear] as keyof Entity) : undefined;
    const priceObject = yearValue ? (yearValue as unknown as Entity) : undefined;

    switch (colDef.type) {
      case 'entity':
      case 'select':
        valueToCopy = cellValue;
        break;

      case 'formula':
        valueToCopy = cellValue ? cellValue.replace(/,/g, '') : '';
        break;

      case 'number':
        switch (valueFieldName) {
          case 'volume':
          case 'forecast':
          case 'retailValue':
            valueToCopy =
              yearValue !== undefined ? parseFloat(yearValue).toFixed(2).toString() : '';

            break;

          case 'containerSize':
            valueToCopy = yearValue ? parseFloat(yearValue).toString() : '';
            break;

          case 'price':
            if (priceObject) {
              valueToCopy = (priceObject['isEstimated'] as keyof Entity)
                ? '~' +
                  parseFloat(priceObject['value'] as keyof Entity)
                    .toFixed(2)
                    .toString()
                : parseFloat(priceObject['value'] as keyof Entity)
                    .toFixed(2)
                    .toString();
            } else {
              valueToCopy = '';
            }
            break;

          case 'onPremisePercent':
            valueToCopy =
              yearValue !== undefined ? `${(parseFloat(yearValue) * 100).toFixed(0)}%` : '';
            break;

          case 'providedVolume':
            valueToCopy = cellValue ? parseFloat(cellValue).toFixed(2).toString() : '';
            break;

          case 'percentage':
            valueToCopy = yearValue != null ? `${(parseFloat(yearValue) * 100).toFixed(2)}%` : '';
            break;

          default:
            valueToCopy = cellValue.replace(/,/g, '');
            break;
        }

        break;

      case 'text':
        switch (valueFieldName) {
          case 'percentage':
            valueToCopy = yearValue ? `${parseFloat(yearValue).toFixed(2)}%` : '';
            break;

          default:
            valueToCopy = cellValue;
        }

        break;

      default:
        valueToCopy = data[fieldName] as keyof Entity;

        break;
    }

    await navigator.clipboard.writeText(valueToCopy);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

const copyContentFromGroupRow = async (
  colDef: unsafe_ColDef,
  cellValue: string,
  rowNode: unsafe_RowNode | undefined
) => {
  try {
    let valueToCopy = '';
    const fieldName: string = colDef.colId ?? '';
    const valueFieldName: string = fieldName.split('.')[0] ?? '';

    switch (colDef.type) {
      case 'entity':
      case 'linkedRecord':
      case 'text': {
        valueToCopy = cellValue;
        break;
      }

      case 'number':
        switch (valueFieldName) {
          case 'volume':
          case 'forecast':
          case 'retailValue':
          case 'allChannelsVolume':
            valueToCopy = cellValue ? cellValue.replace(/,/g, '') : '';
            break;
        }
        break;

      case 'formula':
        valueToCopy = cellValue ? cellValue.replace(/,/g, '') : '';
        break;
    }

    // Special case for group headings on Forecasts and On-Premise table
    if (colDef.type === 'entity' && rowNode) {
      const refData = rowNode.rowGroupColumn?.getColDef().refData as Entity;
      const entityKey = rowNode.groupData?.[fieldName] as keyof typeof refData;
      valueToCopy = String(refData[entityKey]);
    }

    await navigator.clipboard.writeText(valueToCopy);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

export const sendToClipboard = (params: unsafe_SendToClipboardParams): void => {
  const ranges = params.api.getCellRanges();

  if (isRange(ranges)) {
    void copyRange(ranges![0]!, params.data, params.api);
    return;
  }

  const selectedRowIndex: number | undefined = params.api.getFocusedCell()?.rowIndex ?? undefined;

  if (selectedRowIndex !== undefined) {
    const rowNode = params.api.getDisplayedRowAtIndex(selectedRowIndex);

    if (rowNode?.group) {
      void copyContentFromGroupRow(
        params.api.getFocusedCell()?.column.getColDef() as unknown as unsafe_ColDef,
        params.data,
        params.api.getDisplayedRowAtIndex(selectedRowIndex)
      );
    } else {
      void copyContent(
        params.api.getFocusedCell()?.column.getColDef() as unknown as unsafe_ColDef,
        params.api.getDisplayedRowAtIndex(selectedRowIndex)?.data as unknown as Entity,
        params.data
      );
    }
  }
};

import type { unsafe_ColDef, unsafe_ProcessDataFromClipboardParams } from 'src/components/DataGrid';
import { isRange } from './gridClipboardUtils';

const parseValueFromClipboard = (
  colDef: unsafe_ColDef,
  newValue: string,
  originalValue: unknown
) => {
  if ((colDef.type === 'entity' || colDef.type === 'select') && colDef.refData != null) {
    const refData = colDef.refData;
    const newRefDataKey = Object.keys(refData).find(key => {
      return refData[key] === newValue;
    });

    if (newRefDataKey != null) {
      const newRefDataKeyAsInteger = parseInt(newRefDataKey);
      if (!isNaN(newRefDataKeyAsInteger)) return newRefDataKeyAsInteger;
      if (typeof originalValue === 'boolean') return newRefDataKey === 'true';
      return newRefDataKey;
    } else {
      return originalValue;
    }
  }

  return newValue;
};

export const pasteFromClipboard = (
  params: unsafe_ProcessDataFromClipboardParams
): string[][] | null => {
  const ranges = params.api.getCellRanges();

  if (isRange(ranges)) {
    console.log('Attempt at pasting to a range detected!');
    return null;
  }

  const selectedRowIndex: number | undefined = params.api.getFocusedCell()?.rowIndex ?? undefined;

  if (selectedRowIndex !== undefined) {
    let originalValue;
    const focusedCell = params.api.getFocusedCell();

    if (focusedCell?.column) {
      const row = params.api.getDisplayedRowAtIndex(focusedCell.rowIndex);

      if (row) {
        const cellValue = params.api.getValue(focusedCell.column, row) as unknown as string;
        originalValue = cellValue;
      }
    }

    if (params.data.flat().length > 1) {
      console.log('Attempt at pasting from a range detected!');
      return null;
    }

    const valueToPaste = parseValueFromClipboard(
      params.api.getFocusedCell()?.column.getColDef() as unknown as unsafe_ColDef,
      String(params.data[0]![0]),
      originalValue
    );

    return [[valueToPaste as string]];
  } else {
    return null;
  }
};

import type {
  unsafe_CellRange,
  unsafe_ColDef,
  unsafe_Column,
  unsafe_GridApi,
  unsafe_RowNode,
  unsafe_ValueFormatterParams,
} from 'src/components/DataGrid';
import type { Entity } from 'src/types/Entity';
import type { ValueFormatterParams } from 'src/types/valueFormatterTypes';

const TAB = '\t';
const NEWLINE = '\r\n';

// create a grid of rows x columns
const createGrid = (rows: string[], rangeColumns: unsafe_Column[]): string[][] => {
  return rows.map(row => {
    const cols = row.split(TAB);

    if (cols.length !== rangeColumns.length) {
      throw new Error('Number of columns in row not equal that expected!');
    }

    return cols;
  });
};

const getGroupedColDefs = (range: unsafe_CellRange, grid: string[][], api: unsafe_GridApi) => {
  const startRow = range.startRow?.rowIndex ?? -1;
  const arr = [];

  for (let i = 0; i < grid.length; i++) {
    arr.push(
      api.getDisplayedRowAtIndex(startRow + i)?.rowGroupColumn?.getUserProvidedColDef() ?? undefined
    );
  }

  return arr;
};

const getRowNodes = (range: unsafe_CellRange, grid: string[][], api: unsafe_GridApi) => {
  const startRow = range.startRow?.rowIndex ?? -1;
  const arr = [];

  for (let i = 0; i < grid.length; i++) {
    arr.push(api.getDisplayedRowAtIndex(startRow + i) ?? undefined);
  }

  return arr;
};

const getFromGroupedColDefs = (
  rowIndex: number,
  cellValue: string,
  rowNodes: unsafe_RowNode[],
  colDef: unsafe_ColDef
): string => {
  const rowNode = rowNodes[rowIndex]!;

  const groupColDef = rowNode.rowGroupColumn?.getColDef();
  const refData = groupColDef?.refData as Entity | undefined;
  const rowGroupIndex = groupColDef?.rowGroupIndex;

  let returnValue = cellValue;

  if (cellValue === 'true' || cellValue === 'false') {
    returnValue = refData ? String(refData[cellValue]) : cellValue;
  }

  // Special case for group headings on Ecommerce, Forecasts and On-Premise tables
  if (!cellValue) {
    const fieldName = colDef.colId ?? '';
    const entityKey = rowNode.groupData?.[fieldName] as keyof typeof refData;
    returnValue = String(refData?.[entityKey]);
  }

  return `${'>  '.repeat(rowGroupIndex! + 1)} ${returnValue}`;
};

const extractPrice = (priceObject: Entity | undefined) => {
  let valueToCopy = '';

  if (priceObject) {
    valueToCopy = (priceObject['isEstimated'] as keyof Entity)
      ? '~' +
        parseFloat(priceObject['value'] as keyof Entity)
          .toFixed(2)
          .toString()
      : parseFloat(priceObject['value'] as keyof Entity)
          .toFixed(2)
          .toString();
  }

  return valueToCopy;
};

const getFromColDef = (
  index: number,
  cellValue: string,
  rangeColumns: unsafe_Column[],
  actualRowIndex: number,
  api: unsafe_GridApi
) => {
  const colDef = rangeColumns[index]!.getColDef();

  const fieldName: string = colDef.colId ?? '';
  const valueFieldName: string = fieldName.split('.')[0] ?? '';
  const valueFieldYear: string = fieldName.split('.')[1] ?? '';

  if (colDef.type === 'number' && valueFieldName === 'price') {
    const actualRowData = api.getDisplayedRowAtIndex(actualRowIndex)?.data as unknown as
      | Entity
      | undefined;

    const yearValues =
      actualRowData?.[valueFieldName] != null
        ? (actualRowData[valueFieldName] as Entity)
        : undefined;

    const yearValue = yearValues ? (yearValues[valueFieldYear] as keyof Entity) : undefined;
    const priceObject = yearValue ? (yearValue as unknown as Entity) : undefined;

    return extractPrice(priceObject);
  }

  const { valueFormatter } = colDef;

  if (typeof valueFormatter === 'function') {
    const valueFormatterParams: Partial<ValueFormatterParams> = {
      value: cellValue,
      colDef: rangeColumns[index]!.getColDef(),
    };

    return valueFormatter(valueFormatterParams as unsafe_ValueFormatterParams);
  }

  return cellValue;
};

export const copyRange = async (range: unsafe_CellRange, data: string, api: unsafe_GridApi) => {
  // split the incoming string into rows
  const rows = data.split(NEWLINE);

  // ensure the rowcount matches the rows as indicated by ag-grid
  if (rows.length !== (range.endRow?.rowIndex || 0) - (range.startRow?.rowIndex || 0) + 1) {
    console.error('Number of rows in range not equal that expected!');
    await navigator.clipboard.writeText(data);
    return;
  }

  const rangeColumns = range.columns; // create a ref to columns in range
  const grid = createGrid(rows, rangeColumns);

  const groupedColDefs = getGroupedColDefs(range, grid, api);
  const rowNodes = getRowNodes(range, grid, api);

  // transform the columns as needed
  let colCount = 0;
  const colsPerRow = range.columns.length;

  const clipboardData = grid.reduce((concatenator, gridColumns) => {
    const alteredColumns = (gridColumns as unknown as string[]).map(
      (value: string, index: number) => {
        colCount++;

        const rowIndex =
          Math.floor(colCount / colsPerRow) -
          1 * ((colCount % colsPerRow === 0) as unknown as number);

        const colDef = rangeColumns[index]!.getColDef();

        // provide for ecommerce totals descriptions
        if (colDef.type === 'entity' && rowNodes[rowIndex] !== undefined) {
          return getFromGroupedColDefs(rowIndex, value, rowNodes as unsafe_RowNode[], colDef);
        }

        return colDef.type === 'linkedRecord' &&
          !colDef.refData &&
          groupedColDefs[rowIndex] !== undefined
          ? getFromGroupedColDefs(rowIndex, value, rowNodes as unsafe_RowNode[], colDef)
          : getFromColDef(index, value, rangeColumns, range.startRow!.rowIndex + rowIndex, api);
      }
    );

    // concatenate into the one string with linebreaks and tabs
    return concatenator
      .concat(concatenator.length ? NEWLINE : '')
      .concat((alteredColumns as unknown as string[]).join(TAB));
  }, '');

  await navigator.clipboard.writeText(clipboardData);
};
